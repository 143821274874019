import { useCookies } from 'react-cookie';
import { useAxios } from './useAxios';
import { TerminalsDTO, TerminalsRequestDTO } from '../../dtos/terminal/terminalDTO';

type ApiResponse<T> = {
  data: T[];
};

export function useTerminalAPI() {
  const [cookies] = useCookies(['lynk.access-token', 'lynk.partner-id']);
  const authToken = {
    headers: { Authorization: `Bearer ${cookies['lynk.access-token']}` },
  };

  const { getApi, postApi, patchApi, deleteApi } = useAxios();

  async function getTerminals(): Promise<ApiResponse<TerminalsDTO>> {
    return getApi(`/terminals`, authToken, false, true, true, 2);
  }

  async function postTerminal(data: TerminalsRequestDTO): Promise<TerminalsDTO> {
    return postApi(`/terminals`, data, authToken, () => '', false, false, 2);
  }

  async function getTerminalById(terminalId: string): Promise<TerminalsDTO> {
    return getApi(`/terminals/${terminalId}`, authToken, false, true, true, 2);
  }

  return {
    getTerminals,
    postTerminal,
    getTerminalById,
  };
}

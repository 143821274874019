import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { makeStyles } from '@mui/styles';
import { color } from '../../../consts/ColorConst';
import { Typography } from '@mui/material';
import { KarteraButton } from '../../kartera/button';
import { KarteraTextField } from '../../kartera/textField';
import { KarteraSwitch } from '../../kartera/switch';
import { KarteraUploadButton } from '../../kartera/uploadButton';
import { PaymentWidgetBadge } from '../../kartera/paymentWidgetBadge/PaymentWidgetBadge';
import { UploadImageErrorDialogue } from '../../platforms/uploadImageErrorDialogue';
import { useApi } from '../../../services/api/hooks';

const useStyles = makeStyles((theme: any) => ({
  container: {
    width: 840,
    padding: '20px 24px',
    background: color.WHITE_01,
    borderRadius: 6,
    boxShadow: theme.shadow.elevation4Grey,
    minHeight: 747,
  },
  title: {
    fontSize: 16,
    fontWeight: 500,
    paddingBottom: 12,
  },
  logoContainer: {
    display: 'flex',
    gap: 10,
    paddingRight: '12px',
  },
  checkBoxContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 6,
  },
  logoBox: {
    display: 'flex',
    alignItems: 'center',
    minWidth: 112,
  },
  imageContainer: {
    width: 48,
    height: 48,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    border: `1px solid ${color.GREY_LIGHT_3}`,
    borderRadius: 4,
    gap: 10,
  },
  text: {
    fontSize: 14,
    fontWeight: 400,
    paddingRight: 20,
    color: color.GREY_DARK_4,
  },
  smallLabel: {
    marginTop: 2,
    fontSize: 11,
    fontWeight: 400,
    color: color.GREY_LIGHT_2,
    textAlign: 'right',
  },
  ugradeText: {
    fontSize: 12,
    fontWeight: 500,
    color: color.GREY_DARK_2,
    '& strong': {
      color: color.GREEN_DARK_1,
    },
  },
  message: {
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: 0.2,
  },
  content: {
    display: 'flex',
    padding: '20px 0',
    justifyContent: 'space-between',
  },
  badgeContainer: {
    display: 'flex',
    flexDirection: 'column',
    '& label': {
      fontSize: 14,
      fontWeight: 500,
      color: color.GREY_DARK_1,
    },
  },
  LIGHT: {
    backgroundColor: theme.palette.light.backgroundColor,
    '& label': {
      color: theme.palette.light.fontColor,
    },
  },
  DARK: {
    backgroundColor: theme.palette.dark.backgroundColor,
    '& label': {
      color: theme.palette.light.fontColor,
    },
  },
  toggleButtonsContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 8,
    '& label': {
      fontSize: 10,
      fontWeight: 400,
      color: color.GREY_LIGHT_1,
    },
  },
  footer: {
    display: 'flex',
  },
  footerRightContent: {
    display: 'flex',
    flex: 1,
    justifyContent: 'flex-end',
    gap: 12,
  },
  badgeWrapper: {
    width: 485,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    marginRight: 15,
    height: 572,
    borderRadius: '6px',
  },
  uploadImageWrapper: {
    display: 'flex',
    width: 80,
    flexDirection: 'column',
    gap: 4,
  },
  editLogoWrapper: {
    display: 'flex',
  },
  label: {
    fontSize: 14,
    fontWeight: 400,
    color: color.GREY_DARK_1,
  },
  rigthContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: 16,
    width: 287,
  },
  savingBox: {
    borderBottom: `1px solid ${color.GREY_LIGHT_4}`,
    paddingBottom: '16px',
  },
  savingPercent: {
    display: 'flex',
    alignItems: 'center',
  },
  percentInputWrapper: {
    padding: '0 6px 0 6px',
  },
  rewardBox: {
    borderBottom: `1px solid ${color.GREY_LIGHT_4}`,
    paddingBottom: '16px',
    display: 'flex',
    flexDirection: 'column',
  },
  appearanceBox: {},
  appearanceTitle: {
    fontSize: 16,
    fontWeight: 500,
  },
  generalDescription: {
    fontSize: 12,
    fontWeight: 400,
    color: color.GREY_DARK_1,
    paddingBottom: '6px',
  },
  image: {
    margin: '0 20px',
  },
  button: {
    width: 'fit-content',
    padding: 0,
  },
}));

type ModeType = 'LIGHT' | 'DARK';
type ThemeType = 'KARTERA' | 'FIRE' | 'MINIMALIST' | 'NEUTRAL' | 'OCEAN' | 'SUNSET';
type LogoType = 'BLACK' | 'CUSTOM';
type ErrorType = 'SIZE' | 'FORMAT' | 'DIMENSION' | 'UNKNOWN' | undefined;

type Props = {
  onClose: () => void;
};

export function CustomizeWidgetDialog({ onClose }: Props) {
  const { t } = useTranslation('translation', { keyPrefix: 'paymentWidget' });
  const classes = useStyles();

  const { getTerminals, getTerminalById, postTerminal, getWidget, postWidget, updateWidget } =
    useApi();

  const [title, setTitle] = useState<string>(t('defaultTitle'));
  const [theme, setTheme] = useState<ThemeType>('KARTERA');
  const [mode, setMode] = useState<ModeType>('LIGHT');
  const [logoType, setLogoType] = useState<LogoType>('BLACK');
  const [showPoweredByKartera, setShowPoweredByKartera] = useState(true);
  const [showSavings, setShowSavings] = useState(true);
  const [allowCashBack, setAllowCashBack] = useState(true);
  const [brandColor, setBrandColor] = useState<string>('59CE8F');
  const [imageError, setImageError] = useState<ErrorType>();
  const [customLogo, setCustomLogo] = useState<any>();
  const [useCreditStatus, setUseCreditStatus] = useState<boolean>(false);
  const [terminal, setTerminal] = useState<any>({});
  const [widget, setWidget] = useState<any>({});
  const [logoFile, setLogoFile] = useState<any>();
  const [applySavings, setApplySavings] = useState(false);
  const [savingPercent, setSavingPercent] = useState(1.0);

  const charLeft = 25 - title.length;
  const isDefault =
    title === t('defaultTitle') && showPoweredByKartera && allowCashBack && showSavings;

  function handleTitle(text: string) {
    if (text.length <= 25) {
      if (text === '') {
        setTitle(t('defaultTitle'));
      }
      setTitle(text);
    }
  }

  function handleBrandColor(text: string) {
    if (text.length <= 25) {
      if (text === '') {
        setBrandColor('59CE8F');
      }
      setBrandColor(text);
    }
  }

  const formData = new FormData();

  async function handleImageUpload(event: any) {
    try {
      setImageError(undefined);
      const file = event.target.files[0];
      if (!file) return;
      if (file.size > 2 * 1024 * 1024) {
        setImageError('SIZE');
        return;
      }
      if (!['image/jpeg', 'image/png', 'image/jpg'].includes(file.type)) {
        setImageError('FORMAT');
        return;
      }
      const image = new Image();
      const reader = new FileReader();
      image.onload = () => {
        if (image.width < 30 || image.height < 30) {
          setImageError('DIMENSION');
          return;
        }
        setCustomLogo(image.src);
        setLogoType('CUSTOM');
        reader.onloadend = function () {
          setCustomLogo(reader.result);
        };
        reader.readAsDataURL(file);
      };
      image.onerror = () => {
        setImageError('UNKNOWN');
        return;
      };
      image.src = window.URL.createObjectURL(file);
      setLogoFile(file);
    } catch (error: any) {
      setImageError('UNKNOWN');
    }
  }

  function handleDeleteLogo() {
    setCustomLogo('');
    setLogoType('BLACK');
  }

  async function handleConfirm() {
    const data = {
      settings: {
        title: `${title}`,
        brand_color: `${brandColor}`,
        logo_image: `${customLogo}`,
      },
    };
    if (terminal.id) {
      if (widget.id) {
        const updateResponse = await updateWidget(terminal.terminal_key, data);
      } else {
        const postWidgetResponse = postWidget(terminal.terminal_key, data);
      }
    } else {
      const postTerminalResponse = await postTerminal({
        notification_type: 'EMAIL',
        terminal_type: 'TEST',
        notification_email: 'string',
        notification_url: 'string',
      });
      const terminal_key = postTerminalResponse.terminal_key;
      const postWidgetResponse = postWidget(terminal_key, data);
    }
  }

  async function getData() {
    const response = await getTerminals();
    if (response.data.length > 0) {
      setTerminal(response.data[0]);
      const responseWidget = await getWidget(response.data[0].terminal_key);
      setWidget(responseWidget);
      setTitle(responseWidget.widget_settings.title);
      setBrandColor(responseWidget.widget_settings.brand_color);
      responseWidget.widget_settings.logo_image &&
        setCustomLogo(responseWidget.widget_settings.logo_image);
      responseWidget.widget_settings.logo_image && setLogoType('CUSTOM');
    }
  }

  useEffect(() => {
    getData();
  }, []);

  return (
    <>
      <div className={classes.container}>
        <Typography className={classes.title}>{t('customizePanelTitle')}</Typography>
        <p className={classes.text}>{t('customizePanelDescription')}</p>
        <div className={classes.content}>
          <div className={`${classes.badgeWrapper} ${classes[mode as keyof typeof classes]}`}>
            <div className={classes.badgeContainer}>
              <PaymentWidgetBadge
                logoType={logoType}
                customLogo={customLogo}
                title={title || t('defaultTitle')}
                text={showSavings ? t('defaultText') : undefined}
                buttonText={t('confirmPayment')}
                cashBackText={allowCashBack ? t('cashBackText') : undefined}
                mode={mode}
                theme={theme}
                hidePoweredByKartera={!showPoweredByKartera}
                useCreditStatus={useCreditStatus}
                setUseCreditStatus={setUseCreditStatus}
                brandColor={`#${brandColor}`}
              />
            </div>
          </div>
          <div className={classes.rigthContainer}>
            <div className={classes.savingBox}>
              <Typography className={classes.appearanceTitle}>{t('savingTitle')}</Typography>
              <Typography className={classes.generalDescription}>
                {t('savingDescription')}
              </Typography>
              <div className={classes.savingPercent}>
                <KarteraSwitch
                  onChange={() => setApplySavings(!applySavings)}
                  checked={applySavings}
                />
                <Typography>{t('applySavings')}</Typography>
                <div className={classes.percentInputWrapper}>
                  {' '}
                  <KarteraTextField
                    disabled={!applySavings}
                    width={'50px'}
                    value={savingPercent}
                    onChange={(event: any) => setSavingPercent(event.target.value)}
                  ></KarteraTextField>
                </div>
                <Typography>%</Typography>
              </div>
            </div>
            <div className={classes.rewardBox}>
              <Typography className={classes.appearanceTitle}>{t('rewardTitle')}</Typography>
              <Typography className={classes.generalDescription}>
                {t('rewardDescription')}
              </Typography>
              <KarteraButton text={t('setupRewards')} variant='primary' />
            </div>
            <div className={classes.appearanceBox}>
              <Typography className={classes.appearanceTitle}>{t('appearanceTitle')}</Typography>
              <Typography className={classes.generalDescription}>
                {t('appearanceDescription')}
              </Typography>
              <div className={classes.uploadImageWrapper}>
                <label className={classes.label}>{t('image')}:</label>
                <div className={classes.logoBox}>
                  {customLogo && (
                    <div className={classes.logoContainer}>
                      <div className={classes.imageContainer}>
                        <img src={customLogo} alt='Custom logo' height={36} />
                      </div>
                    </div>
                  )}
                  {logoType === 'BLACK' ? (
                    <KarteraUploadButton
                      onChange={handleImageUpload}
                      fileType='image/jpeg, image/png'
                      type='primary'
                      text={t('upload')}
                    />
                  ) : (
                    <div className={classes.editLogoWrapper}>
                      {' '}
                      <KarteraUploadButton
                        onChange={handleImageUpload}
                        fileType='image/jpeg, image/png'
                        type='secondary'
                        text={t('change')}
                      />
                      <KarteraButton
                        variant='tertiary'
                        text={t('delete')}
                        onClick={handleDeleteLogo}
                      ></KarteraButton>
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div>
              <KarteraTextField
                label={`${t('title')}:`}
                labelStyle='simple'
                placeholder={'59CE8F'}
                fullWidth
                value={title}
                onChange={(e: any) => handleTitle(e.target.value)}
              />
              <div className={classes.smallLabel}>{`${charLeft} ${t('charLeft')}`}</div>
            </div>
            <div>
              <KarteraTextField
                label={`${t('brandColor')}:`}
                labelStyle='simple'
                placeholder={'59CE8F'}
                value={brandColor}
                onChange={(e: any) => handleBrandColor(e.target.value)}
                type={'hex'}
                brandColor={brandColor}
                width={'105px'}
              />
            </div>
            <div className={classes.checkBoxContainer}>
              <div className={classes.ugradeText}>
                <KarteraSwitch
                  label={t('poweredByKartera')}
                  checked={showPoweredByKartera}
                  color='success'
                  disabled
                />
                <Trans i18nKey={'upgradeYourAccount'}>{t('upgradeYourAccount')}</Trans>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.footer}>
          <div className={classes.footerRightContent}>
            <KarteraButton text={t('cancel')} variant='secondary' onClick={onClose} />
            <KarteraButton
              text={t('Confirm')}
              variant='primary'
              disabled={isDefault}
              onClick={handleConfirm}
            />
          </div>
        </div>
      </div>
      {imageError && (
        <UploadImageErrorDialogue
          title={t(`${imageError}ErrorTitle`)}
          text={t(`${imageError}ErrorText`)}
          onCancel={() => setImageError(undefined)}
          onChooseImage={(image: any) => handleImageUpload(image)}
        />
      )}
    </>
  );
}

import { Trans, useTranslation } from 'react-i18next';
import { Box, Theme, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { color } from '../../../consts/ColorConst';
import { truncateText } from '../../../utils/helperFunctions';
import { KarteraButton } from '../button';
import { KarteraSwitch } from '../switch';
import KarteraLogo from '../logo/KarteraLogo';
import { KarteraTooltip } from '../tooltip';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuestionCircle, faAngleDown, faAngleUp } from '@fortawesome/pro-regular-svg-icons';
import { KarteraSelectField } from '../selectField';
import { useState } from 'react';

type theme = 'KARTERA' | 'FIRE' | 'MINIMALIST' | 'NEUTRAL' | 'OCEAN' | 'SUNSET';

type StyleProps = {
  theme?: theme;
  brandColor?: string;
};

const useStyles = makeStyles<Theme, StyleProps>((theme: any) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    gap:20,
    backgroundColor: color.WHITE_01,
    padding: "20px 16px 25px 16px",
    width: 403,
    minHeight: 497,
    borderRadius: '20px',
    boxShadow: theme.shadow.elevation8,
  },
  rootDark: {
    backgroundColor: color.GRAY_DARK_05,
    borderColor: color.GRAY_DARK_05,
    '& p': {
      color: color.WHITE_01,
    },
  },
  title: {
    fontSize: 16,
    fontWeight: 500,
    color: color.GREY_DARK_4,
    textAlign:"center"
  },
  logoContainer: {
    width: 60,
    height: 60,
    backgroundColor: color.GREY_LIGHT_5,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 16,
    border: `1.5px dashed ${color.GREY_DARK_2}`,
    boxSizing: 'border-box',
  },
  logoText: {
    fontSize: 16,
    fontWeight: 500,
    textAlign: 'center',
  },
  text: {
    fontSize: 12,
    fontWeight: 400,
    color: color.GREY_DARK_4,
    letterSpacing: 0.2,
    lineHeight: 1.2,
  },
  paymentBox: {
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
    width: '100%',
  },
  breakdownBox: {
    height: '66px',
    display: 'flex',
    alignItems: 'flex-end',
    flexDirection: 'column',
  },
  number: {
    fontSize: '40px',
    fontWeight: 700,
  },
  dollarSign: {
    fontSize: '20px',
    fontWeight: 700,
    opacity: '40%',
  },
  hideButton: {
    cursor: 'pointer',
    fontSize: '14px',
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    paddingBottom:"6px"
  },
  priceBreakdown: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '195px',
    fontSize: '14px',
    fontWeight: 400,
    color: color.GREY,
  },
  cashBackContainer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    gap: 2,
    fontSize: 12,
    fontWeight: 400,
    color: color.GREY_DARK_4,
    letterSpacing: 0.2,
  },
  button: {
    width: '100% !important',
    backgroundColor: ({ theme, brandColor }) => {
      return brandColor;
    },
    '&:hover': {
      backgroundColor: ({ theme, brandColor }) => {
        return brandColor;
      },
    },
  },
  bottomText: {
    width: '100%',
    fontSize: 10,
    fontWeight: 400,
    color: color.GREY_DARK_1,
    letterSpacing: 0.2,
    textAlign: 'right',
    '& strong': {
      color: ({ theme, brandColor }) => {
        return brandColor;
      },
    },
  },
  topContent: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: 6,
  },
  accountLabel: {
    fontSize: 14,
    fontWeight: 400,
    color: color.GREY_DARK_1,
  },
}));

type Props = {
  mode?: 'DARK' | 'LIGHT';
  theme?: theme;
  title?: string;
  text?: string;
  buttonText: string;
  cashBackText?: string;
  tinyBadgeVariant?: 'GREEN' | 'WHITE' | 'BLACK';
  logoType?: 'BLACK' | 'GREEN' | 'GRAY' | 'CUSTOM';
  customLogo?: string;
  hidePoweredByKartera?: boolean;
  useCreditStatus: boolean;
  setUseCreditStatus: (useCreditStatus: boolean) => void;
  brandColor: string;
};

export function PaymentWidgetBadge({
  title,
  text,
  buttonText,
  cashBackText,
  hidePoweredByKartera,
  mode = 'LIGHT',
  theme,
  logoType = 'BLACK',
  customLogo,
  useCreditStatus,
  setUseCreditStatus,
  brandColor,
}: Props) {
  const classes = useStyles({ theme, brandColor });
  const { t } = useTranslation('translation', { keyPrefix: 'platform' });

  const [showDetails, setShowDetails] = useState(true);

  return (
    <Box className={`${classes.root} ${mode === 'DARK' && classes.rootDark}`}>
      <Box className={classes.topContent}>
        <Typography className={classes.title}>
          {logoType === 'CUSTOM' ? (
            <img src={customLogo} alt='Logo' height={60} width={60} />
          ) : (
            <div className={classes.logoContainer}>
              <Typography className={classes.logoText}>{t('yourLogo')}</Typography>
            </div>
          )}
        </Typography>
        <Typography className={classes.title}>
          {truncateText(title, 25)}{' '}
          {!hidePoweredByKartera && (
            <Typography className={classes.bottomText}>
              <Trans i18nkey='badgePoweredByKartera'>{t('badgePoweredByKartera')}</Trans>
            </Typography>
          )}
        </Typography>
      </Box>
      <Box className={classes.paymentBox}>
        <Typography className={classes.number} color={brandColor}>
          <span className={classes.dollarSign} color={brandColor}>
            $
          </span>
          <span className={classes.dollarSign} color={brandColor}>
            {' '}
          </span>
          18.59
        </Typography>
        <Box className={classes.breakdownBox}>
          <Box
            className={classes.hideButton}
            color={brandColor}
            onClick={() => setShowDetails(!showDetails)}
          >
            {showDetails ? t('hideDetails') : t('showDetails')}
            {showDetails ? (
              <FontAwesomeIcon width={14} icon={faAngleUp} color={brandColor} />
            ) : (
              <FontAwesomeIcon width={14} icon={faAngleDown} color={brandColor} />
            )}
          </Box>
          {showDetails && (
            <Box>
              <Box className={classes.priceBreakdown}>
                <Typography>{t('subTotal')}:</Typography>
                <Typography>$28.00</Typography>
              </Box>
              <Box className={classes.priceBreakdown}>
                <Typography>{t('tax')}:</Typography>
                <Typography>$3.19</Typography>
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      {cashBackText && (
        <div className={classes.cashBackContainer}>
          <KarteraSwitch
            checked={useCreditStatus}
            onClick={() => setUseCreditStatus(!useCreditStatus)}
            theme={theme}
            brandColor={brandColor}
          />
          <Trans>
            <p>{cashBackText}</p>
          </Trans>
          <KarteraTooltip title={'Hey'} placement='right'>
            <FontAwesomeIcon width={28} icon={faQuestionCircle} color={color.GREY_DARK_1} />
          </KarteraTooltip>
        </div>
      )}
      <div>
        <p className={classes.accountLabel}>{t('account')}:</p>
        <KarteraSelectField
          width={'371px'}
          items={[
            { value: 'Accoun_1', text: 'Account 1' },
            { value: 'Accoun_2', text: 'Account 2' },
          ]}
          brandColor={brandColor}
        />
      </div>
      <KarteraButton text={buttonText} mode={mode} className={classes.button} spacious />
    </Box>
  );
}

import { FC } from 'react';
import { Switch } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { color } from '../../../consts/ColorConst';
import { SwitchProps } from './types';

type StyleProps = {
  checked?: boolean;
  theme?: 'KARTERA' | 'OCEAN' | 'SUNSET' | 'FIRE' | 'NEUTRAL' | 'MINIMALIST';
  brandColor?: string;
};

const useStyles = makeStyles<any, StyleProps>(() => ({
  container: {
    display: 'flex',
    alignItems: 'center',
  },
  root: {
    '& .MuiSwitch-thumb': {
      backgroundColor: color.WHITE_01,
    },
    '&:active': {
      '& .MuiSwitch-thumb': {
        backgroundColor: color.GREY_LIGHT_4,
      },
    },
    '&:hover': {
      '& .MuiSwitch-thumb': {
        backgroundColor: color.GREY_LIGHT_5,
      },
    },
    '& .MuiSwitch-track': {
      backgroundColor: `${color.GREY_LIGHT_2} !important`,
      opacity: 1,
    },
  },
  checked: {
    '& .MuiSwitch-thumb': {
      backgroundColor: ({ checked, theme, brandColor }) => {
        if (brandColor) {
          return `${brandColor} !important`;
        } else return `${color.GREEN} !important`;
      },
    },
    '& .MuiSwitch-track': {
      backgroundColor: ({ checked, theme, brandColor }) => {
        if (brandColor) {
          return `${brandColor} !important`;
        } else return `${color.GREEN_LIGHT_2} !important`;
      },
    },
    '&:active': {
      '& .MuiSwitch-thumb': {
        backgroundColor: ({ checked, theme, brandColor }) => {
          if (brandColor) {
            return `${brandColor} !important`;
          } else return `${color.GREEN_DARK_2} !important`;
        },
      },
    },
    '&:hover': {
      '& .MuiSwitch-thumb': {
        backgroundColor: ({ checked, theme, brandColor }) => {
          if (brandColor) {
            return `${brandColor} !important`;
          } else return `${color.GREEN_DARK_1} !important`;
        },
      },
    },
  },
  disabled: {
    '& .MuiSwitch-thumb': {
      backgroundColor: `${color.WHITE_01} !important`,
    },
    '&:active': {
      '& .MuiSwitch-thumb': {
        backgroundColor: `${color.WHITE_01} !important`,
      },
    },
    '&:hover': {
      '& .MuiSwitch-thumb': {
        backgroundColor: `${color.WHITE_01} !important`,
      },
    },
    '& .Mui-checked': {
      '& .MuiSwitch-thumb': {
        backgroundColor: `${color.GREEN_LIGHT_2} !important`,
      },
      '&:hover': {
        '& .MuiSwitch-thumb': {
          backgroundColor: `${color.GREEN_LIGHT_2} !important`,
        },
      },
    },
  },
  labelStyle: {
    fontFamily: 'inherit',
    fontSize: 14,
    fontWeight: 400,
    color: color.GREY_DARK_4,
  },
  labelError: {
    color: color.RED_DARK_1,
  },
  labelDisabled: {
    color: color.GREY_LIGHT_2,
  },
  hasError: {
    '& .MuiSwitch-thumb': {
      backgroundColor: color.WHITE_01,
    },
    '& .Mui-checked': {
      '& .MuiSwitch-thumb': {
        backgroundColor: color.RED_DARK_1,
      },
      '&:hover': {
        '& .MuiSwitch-thumb': {
          backgroundColor: color.RED_DARK_1,
        },
      },
    },
    '& .MuiSwitch-track': {
      backgroundColor: `${color.RED_LIGHT_3} !important`,
    },
  },
}));

export const KarteraSwitch: FC<SwitchProps> = ({
  label,
  checked,
  disabled,
  error,
  sx,
  theme,
  brandColor,
  ...rest
}) => {
  const classes = useStyles({ checked, theme, brandColor });

  return (
    <div className={classes.container} style={sx}>
      <Switch
        size='small'
        color='success'
        checked={checked}
        disabled={disabled}
        className={`
          ${classes.root} 
          ${checked && classes.checked} 
          ${disabled && classes.disabled} 
          ${error && classes.hasError}
        `}
        disableRipple
        disableFocusRipple
        disableTouchRipple
        {...rest}
      />
      {label && (
        <label
          className={`
            ${classes.labelStyle} 
            ${error && classes.labelError} 
            ${disabled && classes.labelDisabled} 
          `}
        >
          {label}
        </label>
      )}
    </div>
  );
};

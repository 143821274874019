import { useCookies } from 'react-cookie';
import { useAxios } from './useAxios';
import { WidgetDTO, WidgetRequestDTO } from '../../dtos/widget/widgetDTO';

export function useWidgetAPI() {
  const [cookies] = useCookies(['lynk.access-token', 'lynk.partner-id']);
  const authToken = {
    headers: { Authorization: `Bearer ${cookies['lynk.access-token']}` },
  };

  const { getApi, postApi, patchApi, deleteApi } = useAxios();

  async function getWidget(terminalId: string): Promise<WidgetDTO> {
    return getApi(`/terminals/${terminalId}/widget_settings`, authToken, false, true, true, 2);
  }

  async function postWidget(terminalId: string, data: any): Promise<WidgetDTO> {
    const params = {
        headers: {
          Authorization: `Bearer ${cookies['lynk.access-token']}`,
          'Content-Type': `multipart/form-data`,
        },
      };
    return postApi(`/terminals/${terminalId}/widget_settings`, data, authToken, () => '', false, false, 2);
  }

  async function updateWidget(terminalId: string, data: any): Promise<WidgetDTO> {
    const params = {
        headers: {
          Authorization: `Bearer ${cookies['lynk.access-token']}`,
          'Content-Type': 'multipart/form-data',
        },
      };
    return patchApi(`/terminals/${terminalId}/widget_settings`, data, authToken, () => '', false, 2);
  }

  return {
    getWidget,
    postWidget,
    updateWidget,
  };
}

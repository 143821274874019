import { FC, useEffect, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Input, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faXmarkCircle } from '@fortawesome/free-solid-svg-icons';
import { sentenceCaseGenerator } from '../../../utils/helperFunctions';
import { color } from '../../../consts/ColorConst';
import { TextFieldProps } from './types';
import { useTranslation } from 'react-i18next';
import { containDangerousCharacters } from '../../../utils/handleValuesHelper';

type StyleProps = {
  variant: string;
};

const useStyles = makeStyles<any, StyleProps>((theme) => ({
  inputStyle: {
    height: '28px',
    padding: '6px 10px',
    backgroundColor: color.GREY_LIGHT_5,
    color: color.GREY_DARK_4,
    border: `1.5px solid ${color.GREY_LIGHT_5}`,
    borderRadius: 6,
    [theme.breakpoints.down('md')]: {
      height: '34px',
    },
    '& .MuiInputBase-input': {
      fontSize: '14px',
      fontWeight: 400,
      '&::placeholder': {
        color: color.GREY_DARK_4,
      },
    },
    '& .MuiInputBase-root': {
      border: `1.5px solid ${color.GREY_LIGHT_5}`,
      width: 'fit-content',
    },
    '&:hover': {
      backgroundColor: color.GREY_LIGHT_5,
      borderColor: color.GREY_LIGHT_3,
      boxShadow: theme.shadow.elevation4Grey,
    },
  },
  inputWhite: {
    backgroundColor: color.WHITE_01,
    color: color.GREY_DARK_2,
    border: `1px solid ${color.GREY_LIGHT_2}`,
    '&:hover': {
      backgroundColor: color.WHITE_01,
    },
  },
  root: {
    fontFamily: 'Roboto',
    position: 'relative',
    '& .Mui-focused': {
      backgroundColor: color.WHITE_01,
      border: `1.5px solid ${color.GREEN}`,
      boxShadow: theme.shadow.elevation4Green,
    },
    '& .Mui-disabled': {
      '&:hover': {
        '& .MuiInputBase-input': {
          height: '16px',
        },
        backgroundColor: ({ variant }: any) =>
          variant === 'default' ? color.GREY_LIGHT_5 : color.WHITE_01,
        borderColor: ({ variant }: any) =>
          variant === 'default' ? color.GREY_LIGHT_5 : color.GREY_LIGHT_2,
        boxShadow: 'none',
      },
    },
  },
  spacious: {
    height: '34px',
    borderRadius: 7.5,
    padding: '7px 12px',
  },
  hasError: {
    backgroundColor: color.RED_LIGHT_5,
    borderColor: color.RED,
    '& .MuiInputBase-input': {
      color: color.RED_DARK_3,
      '&::placeholder': {
        color: color.RED_DARK_3,
      },
    },
  },
  errorMessage: {
    margin: '2px 0 0 2px',
    fontFamily: 'inherit',
    fontSize: 11,
    color: color.RED,
  },
  subNote: {
    margin: '2px 0 0 2px',
    fontSize: '10px',
    letterSpacing: 0.3,
    color: color.GREY_DARK_1,
  },
  icon: {
    height: '11px',
    color: color.GREY_LIGHT_2,
    '&:hover': {
      color: color.GREY,
    },
  },
  label: {
    display: 'block',
    fontSize: 14,
    fontWeight: 400,
    letterSpacing: 0.4,
    marginBottom: 4,
    color: color.GREY_DARK_1,
  },
  title: {
    marginBottom: 12,
    color: color.GREY_DARK_4,
  },
  labelDisabled: {
    color: color.GREY_LIGHT_2,
  },
}));

export const KarteraTextField: FC<TextFieldProps> = ({
  id,
  type = 'text',
  variant = 'default',
  spacious,
  width = '200px',
  fullWidth,
  label,
  labelStyle = 'title',
  errorMessage,
  error,
  sx,
  subNote,
  onCancel,
  onBlur,
  disabled,
  inputProps,
  checkDangerousContent,
  setCheckError,
  brandColor,
  ...rest
}) => {
  const classes = useStyles({ variant });
  const { t } = useTranslation('translation', { keyPrefix: 'components' });

  const [showPassword, setShowPassword] = useState(false);
  const [onFocus, setOnFocus] = useState(false);
  const [onCancelButtomFocus, setOnCancelButtonFocus] = useState(false);
  const [_errorMessage, setErrorMessage] = useState(errorMessage || '');

  function handleBlur(e: any) {
    setOnFocus(false);
    if (checkDangerousContent) {
      if (containDangerousCharacters(e.target.value)) {
        setErrorMessage(t('dangerousContentError'));
        setCheckError && setCheckError(t('dangerousContentError'));
      } else {
        setErrorMessage(errorMessage || '');
        setCheckError && setCheckError('');
      }
    }

    if (onBlur) onBlur(e);
  }

  useEffect(() => {
    setErrorMessage(errorMessage || '');
  }, [errorMessage]);

  return (
    <div className={classes.root} style={{ ...sx, width: fullWidth ? '100%' : width }}>
      {label && (
        <label
          className={`
            ${classes.label} 
            ${labelStyle === 'title' && classes.title}
            ${disabled && classes.labelDisabled}
          `}
        >
          {label}
        </label>
      )}
      <Input
        id={id}
        data-testid={id}
        type={type === 'password' ? (showPassword ? 'text' : 'password') : type}
        className={`
          ${classes.inputStyle}
          ${variant === 'white' && classes.inputWhite}
          ${spacious && classes.spacious}
          ${(_errorMessage || error) && classes.hasError}
        `}
        disableUnderline
        fullWidth
        disabled={disabled}
        onBlur={handleBlur}
        onFocus={() => setOnFocus(true)}
        endAdornment={
          (onCancel || type === 'password') && (
            <InputAdornment position='end'>
              {type === 'password' ? (
                <IconButton
                  aria-label='toggle password visibility'
                  edge='end'
                  onClick={() => setShowPassword(!showPassword)}
                  tabIndex={-1}
                  disabled={disabled}
                  disableRipple
                >
                  {showPassword ? (
                    <FontAwesomeIcon icon={faEyeSlash} className={classes.icon} />
                  ) : (
                    <FontAwesomeIcon icon={faEye} className={classes.icon} />
                  )}
                </IconButton>
              ) : (
                (onFocus || onCancelButtomFocus) && (
                  <IconButton
                    aria-label='close button'
                    edge='end'
                    onMouseOver={() => setOnCancelButtonFocus(true)}
                    onClick={onCancel}
                    onMouseLeave={() => setOnCancelButtonFocus(false)}
                    tabIndex={-1}
                    disabled={disabled}
                    disableRipple
                  >
                    <FontAwesomeIcon icon={faXmarkCircle} className={classes.icon} />
                  </IconButton>
                )
              )}
            </InputAdornment>
          )
        }
        startAdornment={
          type === 'hex' && (
            <InputAdornment
              position='start'
              sx={{ display: 'flex', justifyContent: 'center', marginRight: '4px' }}
            >
              <Box
                sx={{ backgroundColor: `#${brandColor}`, width: 14, height: 14, borderRadius: 0.2 }}
              />
              <Typography sx={{ paddingLeft: '4px' }}>#</Typography>
            </InputAdornment>
          )
        }
        inputProps={inputProps}
        {...rest}
      />
      {_errorMessage && (
        <p className={classes.errorMessage}>
          {typeof _errorMessage === 'string' ? sentenceCaseGenerator(_errorMessage) : _errorMessage}
        </p>
      )}
      {subNote && <p className={classes.subNote}>{subNote}</p>}
    </div>
  );
};

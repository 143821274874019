import React, { FC } from "react";
import { UploadButtonProps } from "./types";
import { makeStyles } from "@mui/styles";
import { color } from "../../../consts/ColorConst";
import { Button } from "@mui/material";

const useStyles = makeStyles(() => ({
  button: {
    height: '28px',
    fontSize: '14px',
    fontWeight: 500,
    fontFamily: 'Roboto',
    borderRadius: 6,
    textTransform: 'capitalize',
    padding: '2px 10px',
    border: 'none',
    cursor: 'pointer',
    transition: 'all ease .3s',
    color: color.GRAY_DARK_01,
    '& path': { fill: color.GRAY_DARK_01 },
    '&:disabled': {
      cursor: 'not-allowed',
      color: color.GRAY_LIGHT_01,
      '& path': { fill: color.GRAY_LIGHT_01 },
    },
  },
  content: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  iconWrapper: {
    display: 'flex',
    alignItems: 'center',
    height: '100%',
  },
  iconMargin: {
    marginRight: '4px',
  },
  primary: {
    backgroundColor: color.GREEN_LIGHT_1,
    color: color.WHITE_01,
    '& path': { fill: color.WHITE_01 },
    '&:hover': { backgroundColor: color.GREEN_DARK_1 },
    '&:active': { backgroundColor: color.GREEN_DARK_2 },
    '&:disabled': {
      backgroundColor: color.GREEN_LIGHT_4,
      color: color.WHITE_01,
      '& path': { fill: color.WHITE_01 },
    },
  },
  secondary: {
    backgroundColor: color.SECONDARY,
    '&:hover': { backgroundColor: color.SECONDARY_DARK_01 },
    '&:active': { backgroundColor: color.SECONDARY_DARK_02 },
    '&:disabled': { backgroundColor: color.SECONDARY_LIGHT_01 },
  },
  tertiary: {
    backgroundColor: 'transparent',
    color: color.BLUE_DARK_04,
    '& path': { fill: color.BLUE_DARK_04 },
    '&:hover': {
      color: color.PRIMARY_DARK_01,
      '& path': { fill: color.PRIMARY_DARK_01 },
    },
    '&:active': {
      color: color.PRIMARY_DARK_02,
      '& path': { fill: color.PRIMARY_DARK_02 },
    },
    '&:disabled': {
      color: color.PRIMARY_LIGHT_01,
      '& path': { fill: color.PRIMARY_LIGHT_01 },
    },
  },
  negative: {
    backgroundColor: color.NEGATIVE,
    '&:hover': { backgroundColor: color.NEGATIVE_DARK_01 },
    '&:active': { backgroundColor: color.NEGATIVE_DARK_02 },
    '&:disabled': { backgroundColor: color.NEGATIVE_LIGHT_01 },
  },
  positive: {
    backgroundColor: color.POSITIVE,
    '&:hover': { backgroundColor: color.POSITIVE_DARK_01 },
    '&:active': { backgroundColor: color.POSITIVE_DARK_02 },
    '&:disabled': { backgroundColor: color.POSITIVE_LIGHT_01 },
  },
  notice: {
    backgroundColor: color.NOTICE,
    '&:hover': { backgroundColor: color.NOTICE_DARK_01 },
    '&:active': { backgroundColor: color.NOTICE_DARK_02 },
    '&:disabled': { backgroundColor: color.NOTICE_LIGHT_01 },
  },
}));

export const KarteraUploadButton: FC<UploadButtonProps> = ({
  id,
  text,
  icon,
  onChange,
  type = 'primary',
  disabled = false,
  fileType = 'image/*',
  multiple = false,
}) => {
  const classes = useStyles();
  return (
    <Button
      data-testid={id}
      className={`${classes.button} ${classes[type]}`}
      disabled={disabled}
      component='label'
    >
      <div className={classes.content}>
        {icon && (
          <div
            className={`
                ${classes.iconWrapper} 
                ${icon && text && classes.iconMargin}
              `}
          >
            {icon}
          </div>
        )}
        {text}
      </div>
      <input
        style={{ display: 'none' }}
        type='file'
        accept={fileType}
        multiple={multiple}
        onChange={onChange}
        id='input-upload-file'
      />
    </Button>
  );
};

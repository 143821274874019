import axios from "axios";
import { useDispatch } from 'react-redux';
import { useAuth } from '../../context/Auth';
import { setApiErrorMessage, setApiHasError } from "../../reducers/apiErrorReducers";
import { setIsLoading } from "../../reducers/loaderReducers";

const BASE_URL = process.env.REACT_APP_API_URL;

const apiV1 = axios.create({
  baseURL: BASE_URL + 'v1/',
  headers: {
    'Content-Type': 'application/json',
    Accept: '*/*',
    'Cache-Control': 'no-cache',
  },
});

const apiV2 = axios.create({
  baseURL: BASE_URL + 'v2/',
  headers: {
    'Content-Type': 'application/json',
    Accept: '*/*',
    'Cache-Control': 'no-cache',
  },
});

const apiWithoutVersion = axios.create({
  baseURL: BASE_URL,
  headers: {
    'Content-Type': 'application/json',
    Accept: '*/*',
    'Cache-Control': 'no-cache',
  },
});

export function useAxios() {
  const dispatch = useDispatch();
  const { logout } = useAuth();

  function setIsApiLoading(isLoading: boolean) {
    dispatch(setIsLoading(isLoading));
  }

  function setHasError(hasError: boolean) {
    dispatch(setApiHasError(hasError));
  }

  function setErrorMessage(message: string) {
    dispatch(setApiErrorMessage(message));
  }

  async function getApi(
    url: string,
    params?: any,
    showLoading = true,
    throwError = false,
    withVersion = true,
    version = 1,
  ) {
    try {
      showLoading && setIsApiLoading(true);
      let response;
      if (withVersion) {
        if (version === 1) {
          response = await apiV1.get(url, params);
        } else {
          response = await apiV2.get(url, params);
        }
      } else {
        response = await apiWithoutVersion.get(url, params);
      }
      showLoading && setIsApiLoading(false);
      setHasError(false);
      return response.data;
    } catch (error: any) {
      showLoading && setIsApiLoading(false);
      if (throwError) throw error;

      if (401 === error?.response?.status) {
        logout();
      }
      setHasError(true);
      error?.response?.data?.message && setErrorMessage(error?.response?.data?.message);
      console.log(error);
    }
  }

  async function deleteApi(url: string, params?: any, showLoading = true, version = 1) {
    try {
      showLoading && setIsApiLoading(true);
      let response;
      if (version === 1) {
        response = await apiV1.delete(url, params);
      } else {
        response = await apiV2.delete(url, params);
      }
      showLoading && setIsApiLoading(false);
      setHasError(false);
      return response.data;
    } catch (error: any) {
      showLoading && setIsApiLoading(false);
      if (401 === error?.response?.status) {
        logout();
      }
      setHasError(true);
      error?.response?.data?.message && setErrorMessage(error?.response?.data?.message);
      console.log(error);
    }
  }

  async function postApi(
    url: string,
    data: any,
    params?: any,
    callbackError?: (error: any) => void,
    showLoading = true,
    throwError = false,
    version = 1,
  ) {
    try {
      showLoading && setIsApiLoading(true);
      let response;
      if (version === 1) {
        response = await apiV1.post(url, data, params);
      } else {
        response = await apiV2.post(url, data, params);
      }
      showLoading && setIsApiLoading(false);
      setHasError(false);
      return response.data;
    } catch (error: any) {
      showLoading && setIsApiLoading(false);
      if (throwError) throw error;

      if (401 === error?.response?.status) {
        logout();
      }
      setHasError(true);
      error?.response?.data?.message && setErrorMessage(error?.response?.data?.message);
      callbackError && callbackError(error.response.data);
    }
  }

  async function patchApi(
    url: string,
    data: any,
    params?: any,
    callbackError?: (error: any) => void,
    showLoading = true,
    version = 1,
  ) {
    try {
      showLoading && setIsApiLoading(true);
      let response;
      if (version === 1) {
        response = await apiV1.patch(url, data, params);
      } else {
        response = await apiV2.patch(url, data, params);
      }
      showLoading && setIsApiLoading(false);
      setHasError(false);
      return response.data;
    } catch (error: any) {
      showLoading && setIsApiLoading(false);
      if (401 === error?.response?.status) {
        logout();
      }
      error?.response?.data?.message && setErrorMessage(error?.response?.data?.message);
      setHasError(true);
      callbackError && callbackError(error.response.data);
    }
  }

  return {
    getApi,
    postApi,
    patchApi,
    deleteApi,
  };
}